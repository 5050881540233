.btn-secondary {
    background-color: #333333 !important;
}

.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary.active {
    background-color: #888888!important;
}

.tooltip-inner {
    display: inline-block;
    position: relative;
    text-align: left;
    color: #333;
    background-color: #fff;
    border-width: 2px;
    border-style: solid;
    border-color: #fff;
    padding: 10px;
    margin: 0 0 10px 0;
    filter: drop-shadow(rgba(0, 0, 0, 0.4) 0 2px 3px);
}

.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.bs-tooltip-top .arrow::before {
    margin-left: -3px;
    content: "";
    border-width: 5px 5px 0;
    border-top-color: #fff;
}

.tooltip.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.bs-tooltip-bottom .arrow::before {
    
    content: "";
    border-width: 0 10px 10px;
    border-bottom-color: #fff;
}

input[type=checkbox] {
    /* Double-sized Checkboxes */
    -ms-transform: scale(2); /* IE */
    -moz-transform: scale(2); /* FF */
    -webkit-transform: scale(2); /* Safari and Chrome */
    -o-transform: scale(2); /* Opera */
    padding: 10px;
}