tr {
    color: white !important;
}

tr:hover {
    color: white !important;
}

td {
    vertical-align: middle!important;
}
