/*
Awesome Template
http://www.templatemo.com/preview/templatemo_450_awesome
*/

body
	{
 		background: #303030;
 		color: #ffffff;
		font-family: 'Open Sans', sans-serif;
		font-weight: 300;
		overflow-x: hidden; /* prevent horizontal scrollbar from appearing */
	}
	
a {
	color: #47C6F8;
}
	
a:hover {
	color: #0FF;
}

/* start h2 */
h2
	{
		text-align: center;
		font-size: 40px;
		padding-bottom: 20px;
		padding-top: 20px;
	}	
h2 span
	{
		color: #28a7e9;
		font-weight: bold;
	}
/* end h2 */

/* start about team service portfolio contact */
#team, #about, #service,
#portfolio, #contact
	{
		padding-top: 60px;
		padding-bottom: 40px;
	}
/* end about team service portfolio contact */

/* start preloader */
.preloader
	{
		position: fixed;
  		top: 0;
  		left: 0;
  		width: 100%;
  		height: 100%;
  		z-index: 99999;
  		display: -webkit-box;
  		display: -webkit-flex;
  		display: -ms-flexbox;
  		display: flex;
  		-webkit-flex-flow: row nowrap;
  		    -ms-flex-flow: row nowrap;
  		        flex-flow: row nowrap;
  		-webkit-box-pack: center;
  		-webkit-justify-content: center;
  		    -ms-flex-pack: center;
  		        justify-content: center;
  		-webkit-box-align: center;
  		-webkit-align-items: center;
  		    -ms-flex-align: center;
  		        align-items: center;
  		background: none repeat scroll 0 0 #ffffff;
	}
.sk-spinner-wave.sk-spinner 
	{
  		width: 50px;
  		height: 30px;
  		text-align: center;
  		font-size: 10px; 
  	}
.sk-spinner-wave div 
	{
  		background-color: #333;
  		height: 100%;
  		width: 6px;
  		display: inline-block;
         -webkit-animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
                 animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
         
    }
.sk-spinner-wave .sk-rect2 
	{
          -webkit-animation-delay: -1.1s;
                  animation-delay: -1.1s; 
      }
.sk-spinner-wave .sk-rect3 
	{
          -webkit-animation-delay: -1s;
                  animation-delay: -1s; 
     }
.sk-spinner-wave .sk-rect4 
	{
          -webkit-animation-delay: -0.9s;
                  animation-delay: -0.9s;
     }
.sk-spinner-wave .sk-rect5 
	{
          -webkit-animation-delay: -0.8s;
                  animation-delay: -0.8s; 
     }

@-webkit-keyframes sk-waveStretchDelay {
  0%, 40%, 100% {
            -webkit-transform: scaleY(0.4);
                    transform: scaleY(0.4); }

  20% {
            -webkit-transform: scaleY(1);
                    transform: scaleY(1); } }

@keyframes sk-waveStretchDelay {
  0%, 40%, 100% {
            -webkit-transform: scaleY(0.4);
                    transform: scaleY(0.4); }

  20% {
            -webkit-transform: scaleY(1);
                    transform: scaleY(1); } }
/* end preloader */

/* start header */
header
	{
		min-height: 20px;
		padding-top: 0;
		padding-bottom: 10px;
	}
header .fa
	{
		color: #28a7e9;
	}
header span
	{
		font-weight: bold;
		padding-right: 10px;
		padding-left: 4px;
	}
header a
	{
		color: #999;
		font-weight: 600;
	}
header .social-icon
	{
		text-align: right;
	}
header .social-icon li a
	{
		border: none;
		width: 20px;
		height: 20px;
		line-height: 20px;
		text-align: center;
	}
/* end header */

.t2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.t3 
{
	display: -webkit-box!important;
    display: -webkit-flex!important;
    display: -ms-flexbox!important;
    display: flex!important;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
	text-align: center;
	height:100%; 
	padding:20px;
	padding-bottom:50px;
}

/* start nagivation */
.navbar-default {
	background: #fff;
	border: none;
	box-shadow: none;
	margin: 0 !important;
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 0;
    padding-bottom: 0;
}
.navbar-default .navbar-brand
	{
		font-weight: 500;
		font-size: 30px;
		height: 70px;
		line-height: 40px;
	}
.navbar-default .navbar-nav li a
	{
		font-weight: bold;
		font-size: 13px;
		height: 70px;
		line-height: 40px;
	}
	
	.navbar-default .navbar-nav li img
	{
		font-weight: bold;
		font-size: 13px;
		height: 70px;
		line-height: 40px;
	}

.navbar-default .navbar-nav > li > a.current { 
	color: #28A7E9; 
}
.templatemo-nav {
	border-radius: 0;	
	width: 100%;
	z-index: 1000;
}
.sticky {	
	position: fixed;
	left: 0;
	top: 0;	
}

.verticalcenter {
    display: table-cell;
    height: 64px;
    vertical-align: middle;
}
/* end navigation */

/* start home */
#home
	{
		background: url('../images/home-bg.jpg') no-repeat;
		background-size: cover;
		padding-top: 160px;
		padding-bottom: 100px;
		min-height: 650px;
	}
#home h1
	{
		font-weight: 300;
		font-size: 70px;
		padding-bottom: 10px;
	}
#home h1 span
	{
		color: #28a7e9;
		font-weight: bold;
	}
#home .btn
	{
		background: transparent;
		border-radius: 2px;
		color: #fff;
		font-size: 16px;
		font-weight: bold;
		margin-top: 20px;
		width: 180px;
		height: 60px;
		padding-top: 18px;
		-webkit-transition: all 0.4s ease-in-out;
		        transition: all 0.4s ease-in-out;
	}
#home .btn:hover
	{
		background: #28a7e9;
		border-color: transparent;
	}
.element {
	height: 60px;
	font-size: 16px;
}
/* end home */

/* start about */
#team .media-heading-wrapper
	{
		padding-bottom: 32px;
	}
#team .media-heading-wrapper .fa
	{
		border: 1px solid #fff;
		border-radius: 2px;
		color: #fff;
		font-size: 24px;
		width: 60px;
		height: 60px;
		line-height: 60px;
		text-align: center;
		margin-bottom: 20px;
		margin-right: 20px;
	}
#team .media-heading-wrapper h3
	{
		font-size: 18px;
		font-weight: bold;
		padding-top: 20px;
		padding-left: 52px;
	}
/* end about */

/* start team */
#about .team-wrapper
	{
		background: #404040;
		padding-bottom: 10px;
		-webkit-transition: all 0.4s ease-in-out;
		        transition: all 0.4s ease-in-out;
		position: relative;
	}
#about .team-wrapper:hover
	{
		cursor: pointer;
		opacity: 0.9;
	}
#about .team-wrapper .team-des
	{
		padding: 20px;
	}
#about .team-wrapper .team-des span
	{
		color: #28a7e9;
		display: block;
		font-weight: bold;
		padding-bottom: 12px;
	}
/* end team */

/* start service */
#service .col-md-4
	{
		padding: 35px;
		-webkit-transition: all 0.4s ease-in-out;
		        transition: all 0.4s ease-in-out;
	}
#service .col-md-4:hover
	{
		background: #505050;
	}
#service .active
	{
		background: #505050;
		padding: 35px;
	}
#service .fa
	{
		border: 1px solid #fff;
		border-radius: 2px;
		color: #fff;
		font-size: 50px;
		width: 100px;
		height: 100px;
		line-height: 100px;
		text-align: center;
	}
#service h4
	{
		color: #28a7e9;
		font-weight: 600;
		padding-top: 10px;
		padding-bottom: 14px;
	}
/* end service */

/* start portfolio */
#portfolio
	{
		padding-bottom: 80px;
	}
#portfolio .col-md-3
	{
		padding: 0px;
	}
	.portfolio-thumb, 
	.portfolio-overlay {
		max-width: 450px;
	}
#portfolio .portfolio-thumb
	{
		overflow: hidden;
		padding: 0;
		margin: 0 auto;
	}
#portfolio .portfolio-thumb .portfolio-overlay
	{
		background: #28a7e9;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;	
		width: 100%;
		height: 100%;
		opacity: 0;
		position: absolute;
		padding: 20px 10px 20px 20px;
		margin-left: auto;
		margin-right: auto;
		-webkit-transition: all 0.4s ease-in-out;
		        transition: all 0.4s ease-in-out;
	}
#portfolio .portfolio-thumb .portfolio-overlay h4
	{
		font-weight: bold;
		padding-bottom: 10px;
	}
#portfolio .portfolio-thumb .portfolio-overlay .btn
	{
		background: none;
		border: 1px solid #fff;
		border-radius: 2px;
		color: #fff;
		font-weight: bold;
	}
#portfolio .portfolio-thumb:hover .portfolio-overlay
	{
		cursor: pointer;
		opacity: 0.8;
	}
/* end portfolio */

/* start contact */
#contact
	{
		background: #202020;
	}
#contact .form-control
	{
		background: transparent;
		border: 1px solid #28a7e9;
		border-radius: 2px;
		box-shadow: none;
		color: #fff;
		margin-top: 6px;
		margin-bottom: 16px;
	}
	
	#contact .form-control2
	{
		background: #28a7e9;
		border: 1px solid #28a7e9;
		border-radius: 2px;
		box-shadow: none;
		color: #fff;
		margin-top: 6px;
		margin-bottom: 16px;
	}
	
	#contact .form-control2:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  text-decoration: none;
}

#contact .form-control2:hover:before {
  text-decoration: none;
}

#contact label
	{
		font-weight: 500;
	}
#contact input[type="text"],
#contact input[type="radio"],
#contact input[type="password"],
#contact input[type="email"],
#contact input[type="submit"]
	{
		height: 50px;
	}
#contact input[type="submit"]
	{
		background: #28a7e9;
		font-weight: bold;
		-webkit-transition: all 0.4s ease-in-out;
		        transition: all 0.4s ease-in-out;
	}
	
	#contact .alink
	{
		text-align:center;
		vertical-align:middle;
		height: 50px;
		display: inline-block;
		width: 100%;
		background: #28a7e9;
		font-weight: bold;
		-webkit-transition: all 0.4s ease-in-out;
		        transition: all 0.4s ease-in-out;
	}

#contact input[type="submit"]:hover
	{
		background: transparent;
	}
	
	#contact .alink:hover
	{
		background: transparent;
		text-decoration: none;
	}
	
#contact address
	{
		border-bottom: 1px solid #505050;
		padding-top: 30px;
	}
#contact address .address-title
	{
		font-weight: bold;
		font-size: 20px;
		padding-bottom: 10px;
	}
#contact address span
	{
		display: block;
		padding-bottom: 30px;
	}
#contact address .fa
	{
		border: 1px solid #fff;
		border-radius: 2px;
		width: 40px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		margin-right: 10px;
		margin-bottom: 10px;
	}
/* end contact */

/* start copyright */
#copyright
	{
		padding: 10px;
	}
	#copyright p {
		font-weight: 400;
		margin: 0;
	}
/* end copyright */

/* start social icon */
.social-icon
	{
		position: relative;
		padding: 0;
		margin: 0;
	}
.social-icon h4
	{
		display: inline-block;
		padding-right: 20px;
	}
.social-icon li
	{
		display: inline-block;
		list-style: none;
	}
.social-icon li a
	{
		border: 1px solid #fff;
		border-radius: 2px;
		color: #fff;
		width: 40px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		text-decoration: none;
		-webkit-transition: all 0.4s ease-in-out;
		        transition: all 0.4s ease-in-out;
		margin-right: 10px;
	}
.social-icon li a:hover
	{
		background: #28a7e9;
		border-color: transparent;
	}
/* end social icon */

@media only screen and (max-width: 1199px) and (min-width: 992px) {
	#portfolio .portfolio-thumb .portfolio-overlay {
		padding: 0px 8px;
	}
}

/* start 980 media quires */
@media only screen and ( max-width: 980px ) {
	#about .team-wrapper
		{
			margin-top: 40px;
		}
	#portfolio .portfolio-thumb .portfolio-overlay
	{
		background: #28a7e9;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		position: absolute;
		padding: 40px 10px 20px 20px;
		-webkit-transition: all 0.4s ease-in-out;
		        transition: all 0.4s ease-in-out;
	}

}
/* end 360 media quires */

/* start 768 media quires */
@media only screen and ( max-width: 767px ) {
	#home
		{
			min-height: 300px;
			padding-top: 80px;
			padding-bottom: 60px;
		}
	#home h1
		{
			font-size: 52px;
		}

	.templatemo-nav {
		position: fixed;
    	top: 0;
	}
	header {
		margin-top: 70px;
	}
	header .social-icon {
		text-align: left;
	}
	.navbar-toggle {
		margin-top: 15px;
	}
	.navbar-collapse {
		max-height: 300px;
		overflow-y: auto;
	}
	.navbar-default .navbar-nav li a {
		height: 45px;
		padding-top: 5px;
		padding-bottom: 5px;
		line-height: 35px;
	}
}
/* end 768 media quires */

/* start 360 media quires */
@media only screen and ( max-width: 360px ) {
	header .social-icon
		{
			text-align: left;
		}
	#home
		{
			min-height: 200px;
			padding-top: 60px;
			padding-bottom: 40px;
		}
	#home h1
		{
			font-size: 32px;
		}

}
/* end 360 media quires */