a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
    color: white;
    padding: 0;
}

nav .nav-link {
    padding: 15px 15px !important;
    color: #777 !important;
}


html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}
